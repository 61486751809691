.coming-soon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Nutzt die volle Höhe des Viewports */
  text-align: center;
  color: #FFFFFF;
  font-family: 'fangsong', Kailasa; /* Oder eine andere Schriftart deiner Wahl */
  background-image: url('../../img/handstand.jpeg');
  background-size: cover;
  background-position: center;
  position: relative; /* Wichtig für die Positionierung des Pseudo-Elements */
}

.coming-soon-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Schwarz mit 60% Transparenz */
  z-index: 0; /* Stellt sicher, dass das Overlay hinter dem Inhalt liegt */
}

/* Stelle sicher, dass der Inhalt über dem Overlay angezeigt wird */
.countdown, h1, h2, p {
  position: relative;
  z-index: 1;
}

.countdown {
  display: flex;
  margin: 20px 0;
  padding: 10px;
  background: transparent;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.countdown div {
  margin: 0 10px;
}

.countdown span {
  display: block;
  font-size: 5em; /* Größere Zahlen */
  color: #FFFFFF;
}

h2 {
  font-size: 8em; /* Angemessene Größe für den Namen des Studios */
  color: #c40c0c;
  margin-bottom: 20px; /* Reduzierter Abstand */
}

h1 {
  font-size: 3em; /* Anpassung der Größe für "Coming Soon" */
  margin-top: 10px; /* Angemessener Abstand nach oben */
}

p {
  font-size: 1.9em; /* Größere Beschreibung */
  color: #FFFFFF;
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .countdown span, h1, h2, p {
    font-size: 1.5em; /* Anpassung der Schriftgröße für kleinere Geräte */
  }

  h2 {
    font-size: 4em; /* Angemessene Größe für den Namen des Studios */
    color: #c40c0c;
    margin-bottom: 20px; /* Reduzierter Abstand */
  }

  h1 {
    font-size: 2em; /* Anpassung der Größe für "Coming Soon" */
    margin-top: 10px; /* Angemessener Abstand nach oben */
  }

}

@media (max-width: 480px) {
  .countdown {
    flex-direction: column; /* Vertikale Anordnung der Countdown-Elemente auf kleineren Bildschirmen */
  }

  .countdown div, h1, h2, p {
    margin: 5px 0; /* Anpassung des Abstands für vertikale Anordnung */
    font-size: 1.2em; /* Weitere Anpassung der Schriftgröße */
  }

  h2 {
    font-size: 4em; /* Angemessene Größe für den Namen des Studios */
    color: #c40c0c;
    margin-bottom: 20px; /* Reduzierter Abstand */
  }

  h1 {
    font-size: 2em; /* Anpassung der Größe für "Coming Soon" */
    margin-top: 10px; /* Angemessener Abstand nach oben */
  }

}
